<template>
  <div class="detailed-article__aside art-s2">
    <slot name="above"></slot>
    <div class="fixed-block">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useEventListener} from '@vueuse/core';
import {onMounted} from '#imports';
import {useHandleScroll} from '@/composables/useUtils';

onMounted(() => {
  useEventListener(window, 'scroll', useHandleScroll);
});
</script>
